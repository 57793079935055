/** @jsx jsx */
import { jsx } from "theme-ui"

import CookiesSE from "@components/Cookies/CookiesSE"
import CookiesFI from "@components/Cookies/CookiesFI"
const { GATSBY_LOCALE } = process.env

export default function CookiesPage() {
  const cookieLocationMapper = {
    SE: <CookiesSE />,
    FI: <CookiesFI />,
  }
  return cookieLocationMapper[GATSBY_LOCALE] ? (
    cookieLocationMapper[GATSBY_LOCALE]
  ) : (
    <CookiesSE />
  )
}
