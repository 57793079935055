/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Variant from "@components/Variant"
import CookieList from "@components/OneTrust/CookieList"
import CookieSettings from "@components/OneTrust/CookieSettings"

export default function CookiesFI() {
  return (
    <Fragment>
      <Variant
        as="section"
        sx={{ maxWidth: 850, mx: "auto" }}
        variants={["container", "containerSpacing"]}
      >
        <div
          sx={{
            "h1, h2, h3, h4, h5, h6": { textAlign: "left", mt: 12 },
            p: { my: 5 },
          }}
        >
          <h1 sx={{ variant: "richtext.h1" }}>Cookies</h1>

          <h2 sx={{ variant: "richtext.h2" }}>
            Miten Brocc käyttää evästeitä?
          </h2>
          <p sx={{ variant: "richtext.p" }}>
            Käytämme evästeistä saatuja tietoja tehdäksemme verkkosivustostamme
            käyttäjäystävällisemmän ja voidaksemme tarjota yksilöllistä
            sisältöä. Käytämme myös useita valtuutettuja kolmansia osapuolia,
            jotka asettavat puolestamme evästeitä verkkosivustollemme
            tarjotakseen palveluitaan.
          </p>
          <p sx={{ variant: "richtext.p" }}>
            Evästeet voivat sisältää muun muassa käyttäjän asetuksia sekä
            tietoja siitä, millaista sisältöä ja mainoksia olet käyttänyt
            sivustollamme. Tallennettuihin tietoihin voi sisältyä tietoja siitä,
            miten olet selannut ja käyttänyt sivustoamme, käyttämäsi selain,
            näkemiäsi mainoksia ja sitä, miten olet selaillut
            yhteistyökumppaniemme eri sivustojen välillä. Ulkopuolisten
            palveluntarjoajien evästeitä voidaan asettaa liikenteen
            mittaamiseksi, mainosten hallinnan yksinkertaistamiseksi ja
            verkkosivuston toiminnallisuuden parantamiseksi. Saatamme myös
            käyttää ulkopuolisten palveluntarjoajien evästeitä helpottaaksemme
            bannerimainontaa verkkosivustollamme. Tällaiset evästeet seuraavat
            toimintaa verkkosivustollamme, ja niitä käytetään muun muassa
            mainostarjousten räätälöimiseen sinulle.
          </p>
          <p sx={{ variant: "richtext.p" }}>
            Kun käytät verkkosivustoamme, kolmannet osapuolet voivat myös
            sijoittaa evästeitä selaimeesi mitatakseen asiakkaidemme
            bannerimainonnan suorituskykyä. Säätääkseen evästeiden käyttöä
            kolmansien osapuolten toimesta, Brocc on laatinut käytännöt siitä,
            miten verkkosivustomme tietoja voidaan käsitellä. Käytäntömme mukaan
            evästeitä tai muita seurantamenetelmiä ei saa käyttää verkkosivuston
            käyttäjiä koskevien tietojen keräämiseen verkkosivuston
            käyttämiseksi mainostamiseen käyttäjille, tai muihin kaupallisiin
            tarkoituksiin, joissa luokitellaan käyttäjiä. Kolmannet osapuolet
            voivat kerätä anonyymejä tietoja evästeiden tai muiden
            seurantamenetelmien avulla ainoastaan bannerimainosten
            katselumäärän, tavoittavuuden ja konversioiden raportointia ja
            seurantaa varten.
          </p>

          <CookieList />
          <CookieSettings text="Evästeasetukset" />

          <h2 sx={{ variant: "richtext.h2" }}>
            Broccin evästeiden käyttötarkoitus
          </h2>
          <ul sx={{ variant: "richtext.ul" }}>
            <li sx={{ variant: "richtext.li" }}>
              Käytämme evästeitä, jotta verkkosivustomme voi tunnistaa toistuvat
              käyttäjät palvelujen mukauttamiseksi
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Evästeiden käytön tarkoituksia ovat erityisominaisuuksien, kuten
              mainosten ja hälytysten tallentaminen, ja eri toimintojen käytön
              mahdollistaminen
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Käyttäjän liikkumisen helpottaminen sivustolla
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Käyttäjämäärät ja sivuston liikenteen laskeminen, sekä erilaiset
              raportointitarpeet
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Sivuston käytön ymmärtäminen, jotta voimme kehittää ja parantaa
              verkkosivujamme jatkuvasti
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Segmenttien ja kohderyhmien muodostaminen
              markkinointitarkoituksiin ja mainonnan kohdentaminen
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Vähennetään mainontaa, joka ei ole merkityksellistä tietyille
              kohderyhmille
            </li>
          </ul>

          <h2 sx={{ variant: "richtext.h2" }}>
            Mistä voin nähdä, mitä evästeitä tallennetaan?
          </h2>
          <p sx={{ variant: "richtext.p" }}>
            Verkkoselaimet tallentavat yleensä kaikki evästeet tiettyyn
            hakemistoon tietokoneesi muistiin. Yksi tapa selvittää, mitä
            evästeitä tietokoneellesi on tallennettu, on käydä läpi kyseinen
            muisti ja tutkia sen sisältö. Istuntoevästeitä ei kuitenkaan
            tallenneta tietokoneen muistiin, sillä ne vanhentuvat selainistunnon
            päätyttyä.
          </p>

          <h2 sx={{ variant: "richtext.h2" }}>
            Miten voin kieltäytyä evästeiden käytöstä?
          </h2>
          <p sx={{ variant: "richtext.p" }}>
            Voit valita, hyväksytkö evästeet vai et. Asettamalla käyttämäsi
            laitteen sallimaan evästeet, hyväksyt evästeiden vastaanottamisen
            verkkosivustoltamme. Voit määrittää, haluatko, että evästeet
            tallennetaan automaattisesti laitteellesi, haluatko, että sinulta
            kysytään joka kerta, kun eväste halutaan tallentaa, vai haluatko,
            että evästeitä ei tallenneta. Voit tehdä tämän muuttamalla selaimesi
            asetuksia siten, että se ei enää salli evästeitä. Se, miten tämä
            tehdään, riippuu käyttämästäsi selaimesta. Sinun on noudatettava
            samaa menettelyä kaikissa selaimissa, joita käytät samoilla ja/tai
            eri tietokoneilla tai laitteilla. Jos päätät poistaa evästeet
            käytöstä, se estää verkkosivustomme osia toimimasta kunnolla. Et
            esimerkiksi pysty kirjautumaan tilillesi etkä saa henkilökohtaisia
            mainossuosituksia. Lisätietoja evästeistä saat osoitteesta
            https://www.kyberturvallisuuskeskus.fi/fi/toimintamme/saantely-ja-valvonta/evasteet.
          </p>

          <h2 sx={{ variant: "richtext.h2" }}>Yhteystiedot</h2>
          <p sx={{ variant: "richtext.p" }}>
            Jos sinulla on kysyttävää Broccin käyttämistä evästeistä, voit ottaa
            yhteyttä asiakaspalveluumme info@brocc.fi. Voit myös lukea aiheesta
            lisää tietosuojalausunnostamme.
          </p>
        </div>
      </Variant>
    </Fragment>
  )
}
